import { EventEmitter, Injectable } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/storage';
@Injectable({
  providedIn: 'root'
})
export class DataService {
  toggleSidebarActive = new EventEmitter()
  constructor(private firebaseStorage:AngularFireStorage) { }
  changeToggle(data:any, screen: any){
    // console.log(data)
    // console.log(screen)
    if(screen){
      this.toggleSidebarActive.emit(data)
    }
  }
  getFileUrlfromFirestorage(fileData:any,length:any){
    return new Promise(async(resolve,reject)=>{
      let file=fileData.split('/')
      file=file.slice(((file.length)-length)).join('/')
    const filePath=  this.firebaseStorage.ref(file)
    await filePath.getDownloadURL().subscribe((url:any)=>{
    resolve ({url:url})
    }),(err:any)=>{
      reject({message:err})
    }
    })

    }
}
